import Vue from "vue"; //引入Vue
import Router from "vue-router"; //引入vue-router
Vue.use(Router); //Vue全局使用Router
export default new Router({
  mode: "history",
  base: '/index',
  routes: [
    {
      path: "/",
      redirect: "home",
    },
    {
      path: "/404",
      component: () => import("@/views/404"),
      hidden: true,
    },
    {
      path: "/home",
      name: "home",
      meta: { title: "看经首页" },
      component: () => import("@/views/home/index"),
    },
    {
      path: "/buddha",
      name: "buddha",
      meta: { title: "弘扬佛法" },
      component: () => import("@/views/home/buddha"),
    },
    {
      path: "/futian",
      name: "futian",
      meta: { title: "看经福田" },
      component: () => import("@/views/home/futian"),
    },
    {
      path: "/palace",
      name: "palace",
      meta: { title: "走进看经" },
      component: () => import("@/views/home/palace"),
    },
    {
      path: "/pujaRegistration",
      name: "pujaRegistration",
      meta: { title: "法会登记" },
      component: () => import("@/views/form/pujaRegistration"),
    },
    // {
    //   path: "/gongde",
    //   name: "gongde",
    //   meta: { title: "印經功德" },
    //   component: () => import("@/views/printing/gongde"),
    // },
    // {
    //   path: "/huixiang",
    //   name: "huixiang",
    //   meta: { title: "助印迴向" },
    //   component: () => import("@/views/printing/huixiang"),
    // },
    // {
    //   path: "/jieshao",
    //   name: "jieshao",
    //   meta: { title: "經書介紹" },
    //   component: () => import("@/views/printing/jieshao"),
    // },
    // {
    //   path: "/liutong",
    //   name: "liutong",
    //   meta: { title: "經書結緣與流通" },
    //   component: () => import("@/views/printing/liutong"),
    // },
    // {
    //   path: "/mingxi",
    //   name: "mingxi",
    //   meta: { title: "結緣明細" },
    //   component: () => import("@/views/printing/mingxi"),
    // },
    // {
    //   path: "/contactus",
    //   name: "contactus",
    //   meta: { title: "联系我们" },
    //   component: () => import("@/views/printing/contactus"),
    // },
    // {
    //   path: "/popups",
    //   name: "contactus",
    //   meta: { title: "聊天弹窗" },
    //   component: () => import("@/views/printing/popups"),
    // },
  ],
});
