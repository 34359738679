import chineseConv from "chinese-conv";

// 设置字体类型
export function Settingfont(type) {
  
  if (type == "jt") {
    sessionStorage.setItem("zt", "jt");
  } else {
    sessionStorage.setItem("zt", "ft");
  }
  window.location.reload();
}

/**
 *
 * @param {*} text 文字
 */
export function t(text) {
  if (sessionStorage.getItem("zt") === "jt") {
    return jt(text);
  } else {
    return ft(text);
  }
}
// 繁体转简体
export function jt(text) {
  return text ? chineseConv.sify(text) : text;
}
// 简体转繁体
export function ft(text) {
  return text ? chineseConv.tify(text) : text;
}
