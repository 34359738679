<template>
  <div>
    <el-backtop class="backtop" :bottom="190" :right="25"> </el-backtop>
    <div class="header-mobile" v-if="mobile">
      <el-row :gutter="10">
        <el-col :span="12">
          <div style="height: 80px; line-height: 80px">
            <span style="font-size: 20px; padding: 20px">{{
              $t("看经禅寺")
            }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="height: 80px; line-height: 80px; text-align: right">
            <i
              style="font-size: 25px; padding: 20px"
              class="el-icon-s-unfold"
              @click="showMask = true"
              v-if="!showMask"
            ></i>
            <i
              v-if="showMask"
              style="font-size: 25px; padding: 20px"
              class="el-icon-s-fold"
              @click="showMask = false"
            ></i>
          </div>
        </el-col>
      </el-row>
      <div v-if="showMask" class="mask">
        <div @click="scrollToElement('target1')">{{ $t("看经首页") }}</div>
        <div @click="jumpToThePage('/palace', 'target2')">
          {{ $t("走进看经") }}
        </div>
        <div @click="jumpToThePage('/palace', 'target2')">
          {{ $t("寺 院 缘 起") }}
        </div>
        <div @click="jumpToThePage('/palace', 'target3')">
          {{ $t("殿 堂 介 绍") }}
        </div>
        <div @click="jumpToThePage('/palace', 'target4')">
          {{ $t("入 寺 须 知") }}
        </div>
        <div @click="jumpToTheFestivalRegistration">
          {{ $t("法 会 登 记") }}
        </div>
        <div @click="jumpToThePage('/futian', 'target7')">
          {{ $t("看 经 福 田") }}
        </div>
        <div @click="jumpToThePage('/futian', 'target8')">
          {{ $t("修 缮 寺 院") }}
        </div>
        <div @click="jumpToThePage('/futian', 'target9')">
          {{ $t("供 养 三 宝") }}
        </div>
        <div @click="jumpToThePage('/futian', 'target10')">
          {{ $t("牌 位 登 记") }}
        </div>
        <div @click="jumpToThePage('/futian', 'target11')">
          {{ $t("供 奉 佛 像") }}
        </div>
        <div @click="jumpToThePage('/futian', 'target12')">
          {{ $t("印 经 福 田") }}
        </div>
        <div @click="jumpToThePage('/buddha', 'target5')">
          {{ $t("弘 扬 佛 法") }}
        </div>
        <div @click="jumpToThePage('/buddha', 'target13')">
          {{ $t("印 经 功 德") }}
        </div>
        <div @click="jumpToThePage('/buddha', 'target14')">
          {{ $t("经 书 结 缘") }}
        </div>
      </div>
    </div>
    <div class="header" v-else>
      <el-row :gutter="10">
        <el-col :span="10">
          <div class="left">
            <div class="navigationBar">
              <div class="title" @click="jumpToThePage('/home')">
                {{ $t("看经首页") }}
              </div>
              <div class="options"></div>
            </div>
            <div
              class="navigationBar"
              @click.stop="jumpToThePage('/palace', 'target2')"
            >
              <div class="title">{{ $t("走进看经") }}</div>
              <div class="options">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/palace', 'target2')"
                    >
                      {{ $t("寺 院 缘 起") }}
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/palace', 'target3')"
                    >
                      {{ $t("殿 堂 介 绍") }}
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/palace', 'target4')"
                    >
                      {{ $t("入 寺 须 知") }}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="yc"></div>
            </div>
            <div
              class="navigationBar"
              @click="jumpToThePage('/futian', 'target7')"
            >
              <div
                class="title"
                v-if="popUpStatus"
                @mouseenter="handleMouseEnter"
              >
                {{ $t("看经福田") }}
              </div>
              <div class="title" v-else>{{ $t("看经福田") }}</div>
              <div class="options" style="width: 250px">
                <el-row :gutter="10">
                  <el-col :span="4">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/futian', 'target8')"
                    >
                      {{ $t("修 缮 寺 院") }}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/futian', 'target9')"
                    >
                      {{ $t("供 养 三 宝") }}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/futian', 'target10')"
                    >
                      {{ $t("牌 位 登 记") }}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/futian', 'target11')"
                    >
                      {{ $t("供 奉 佛 像") }}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/futian', 'target12')"
                    >
                      {{ $t("印 经 福 田") }}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="yc"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="conter">
            <el-image
              :src="require('@/assets/home/logo.png')"
              width="60%"
              height="80%"
            />
          </div>
        </el-col>
        <el-col :span="10">
          <div class="right">
            <div class="navigationBar" @click="jumpToTheFestivalRegistration">
              <div class="title">{{ $t("法会登记") }}</div>
              <div class="options"></div>
            </div>
            <div
              class="navigationBar"
              @click="jumpToThePage('/buddha', 'target5')"
            >
              <div class="title">{{ $t("弘扬佛法") }}</div>
              <div class="options">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/buddha', 'target13')"
                    >
                      {{ $t("印 经 功 德") }}
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div
                      class="subnavigation"
                      @click.stop="jumpToThePage('/buddha', 'target14')"
                    >
                      {{ $t("经 书 结 缘") }}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="yc"></div>
            </div>
          </div>
          <div class="rights">
            <span
              v-if="font == 'ft'"
              @click="toggleTheFont('jt')"
              :class="font == 'ft' ? 'selected' : 'unchecked'"
              >{{ $t("简") }}</span
            >
            <span
              v-else
              :class="font == 'jt' ? 'selected' : 'unchecked'"
              @click="toggleTheFont('ft')"
              >{{ $t("繁") }}</span
            >
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { Settingfont } from "@/utils/translate.js";
import { deviceJudgment } from "@/utils/index.js";
export default {
  name: "WebsiteHeader",
  components: {},
  data() {
    return {
      showMask: false,
      target: this.$route.query.target,
      popUpStatus: true,
      mobile: deviceJudgment(),
      font: sessionStorage.getItem("zt") || "ft",
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    // 切换字体
    toggleTheFont(type) {
      Settingfont(type);
    },
    // 登记用户
    jumpToThePage(url, target) {
      this.$router.push({ path: url, query: { target: target } });
      this.showMask = false;
      this.$emit("scrollToElement",target);
    },

    // 法会登记
    jumpToTheFestivalRegistration() {
      this.$router.push({ path: "/pujaRegistration" });
    },

    // 弹窗登记
    handleMouseEnter() {
      this.$confirm(
        this.$t("善信是否需要联系寺院客服"),
        this.$t("阿弥陀佛善信吉祥"),
        {
          confirmButtonText: this.$t("确定"),
          cancelButtonText: this.$t("取消"),
        }
      )
        .then(() => {
          ssq.push("chatOpen");
          this.popUpStatus = false;
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t("可点击右下角联系寺院客服哦"),
          });
          this.popUpStatus = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
