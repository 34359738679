<template>
  <div>
    <div class="record">
        <el-row class="lianxi" :gutter="10">
          <el-col :span="10" :xs="24">
            <div class="left">
              <div class="left-dw">
                <p>{{ $t("微信客服：17602027312") }}</p>
                <p>{{ $t("电话：0574-87463108（客堂）") }}</p>
                <p>{{ $t("邮编：315012") }}</p>
              </div>
            </div>
          </el-col>
          <el-col :span="4" :xs="24" v-if="!mobile">
            <div class="conter">
              <el-image :src="require('@/assets/home/logoft.png')" lazy />
            </div>
          </el-col>
          <el-col :span="10" :xs="24">
            <div class="right">
              <div class="right-dw">
                <el-row :gutter="20">
                  <el-col :span="12" style="text-align: center">
                    <el-image
                      :src="require('@/assets/home/whats.png')"
                      style="width: 100px; height: 100px"
                    />
                    <span style="display: block; text-align: center">whatsapp</span>
                  </el-col>
                  <el-col :span="12">
                    <el-image
                      :src="require('@/assets/home/wxsfx.png')"
                      style="width: 100px; height: 100px;"
                    />
                    <span style="display: block; text-align: center"
                      >微信二维码</span
                    >
                  </el-col>
                </el-row>
                <div style="margin-top: 20px">
                  <el-row :gutter="10">
                      <el-col :span="12">
                        <a
                        class="a-btn2"
                       href="https://diversion.salesmartly.com/split/whatsapp/er615a"
                       style="background-color: #25d366"
                      >
                        <el-image
                          :src="require('@/assets/home/whatsappicon.png')"
                          style="vertical-align: middle;width: 20px;height: 20px;"
                        />
                      </a>
                      </el-col>
                      <el-col :span="12">
                        <a
                        class="a-btn2"
                        @click="
                          WindowOpens(
                            'https://www.facebook.com/profile.php?id=61564820916130'
                          )
                        "
                      >
                      <el-image
                          :src="require('@/assets/home/lian.png')"
                          style="vertical-align: middle;width: 20px;height: 20px;"
                        />
                      </a>
                      </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <p style="text-align: center; padding: 10px">
        {{
          $t(
            "互联网宗教信息服务许可证：浙（2022）0000329 版权声明： 2022-2025 看经寺"
          )
        }}
      </p>
      <p style="text-align: center; padding: 10px">
        {{ $t("（国家宗教事务局监制）") }}
      </p>
 </div>
</template>

<script>
import { deviceJudgment } from "@/utils/index.js";

export default {
  name: 'WebsiteEnd',
  components:{
  },
  data() { 
    return {
      mobile: deviceJudgment(),

    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
  },
  watch:{
  },
  methods:{
    WindowOpens(url) {
      window.open(url);
    },
  },
 }
</script>

<style lang='scss' scoped>
</style>