import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/icons";
import "@/styles/index.scss";
import "@/assets/fonts/ali-font.css";

Vue.config.productionTip = false;

import { t } from "@/utils/translate";
Vue.prototype.$t = t;

// 全局注册官网头部<WebsiteHeader>组件
import WebsiteHeader from "@/components/website/WebsiteHeader.vue";
Vue.component("WebsiteHeader", WebsiteHeader);

// 全局注册官网底部<WebsiteEnd>组件
import WebsiteEnd from "@/components/website/WebsiteEnd.vue";
Vue.component("WebsiteEnd", WebsiteEnd);

//谷歌统计插件
import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "G-XB527WHZPF",
  router,
  autoTracking: {
    pageviewOnLoad: false,
  },
});

router.afterEach((to, from, next) => {
  gtag("send", to.meta.title + "页面点击数");
  gtag("event", to.meta.title + "页面点击数");
  document.documentElement.scrollTop = document.body.scrollTop = 0;
  window.scrollTo(0, 0);
});
// 全局引入element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";

// 定义 v-copy 指令
Vue.directive("copy", {
  bind(el, binding) {
    el.addEventListener("click", () => {
      const content = binding.value;
      if (content) {
        copyToClipboard(content);
        showSuccessMessage();
      }
    });
  },
});

function copyToClipboard(text) {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}

function showSuccessMessage() {
  alert("复制成功！");
}
Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
